@tailwind base;
@tailwind components;
@tailwind utilities;
/* Styles personnalisés pour la scrollbar */
.scrollbar-thin::-webkit-scrollbar {
    width: 8px;
  }
  
  .scrollbar-thin::-webkit-scrollbar-track {
    background: #00c355;
    border-radius: 10px;
  }
  
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #00c355;
    border-radius: 10px;
  }
  